.sliderText {
  width: 100%;
  text-align: center;
  justify-content: center;
  align-items: center;
  margin-bottom: 50px;
}

.sliderText h2 {
  width: 100%;
  text-align: center;
  justify-content: center;
  align-items: center;
}

.imageWrapper {
  width: 100%;
  height: 700px;
  position: relative;

  @media screen and (max-width: 500px) {
    height: 500px;
  }
}

.sliderText p {
  padding-left: 10%;
  padding-right: 10%;
}

.sliderItem {
  width: 100%;
  height: 700px;
  position: relative;

  @media screen and (max-width: 500px) {
    height: 500px;
  }
}

.sliderItemOverlay {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  background: transparent linear-gradient(129deg, #9A9393 0%, #9B9494 0%, #BFBFBF 44%, #FFFFFF 60%, #FFFFFF 100%) 0% 0% no-repeat padding-box;
  mix-blend-mode: multiply;
}

.sliderItemContent {
  width: 100%;
  height: 100%;
  position: absolute;
  padding-top: 50px;
  padding-left: 30px;
  top: 0;
}

.sliderItemContent h2 {
  color: white;
  font-size: 32px;
  font-weight: 400;
}

.sliderItemDescription {
  color: white;
  font-size: 16px;
  font-weight: 400;
  margin-top: 10px;
  text-align: left;
  width: 40%;

  @media screen and (max-width: 800px) {
    width: 80%;
  }

  @media screen and (max-width: 605px) {
    font-size: 14px;
    width: 80%;
  }
}

.sliderItemSubImages {
  position: absolute;
  bottom: -50px;
  right: 300px;
  display: flex;
  flex-direction: row;
  transition: all 0.6s ease-out;
  gap: 20px;
  padding: 20px;
  background-color: rgb(140, 184, 40);
}

.sliderItemSubImage1 {
  width: 250px;
  height: 150px;
  position: relative;
}

.sliderItemSubImage2 {
  width: 250px;
  height: 150px;
  position: relative;
}

.embla {
  /* Set a max-width for the carousel container */
  width: 100%;
  position: relative;
  margin: auto;
  --slide-height: fit-content;
  /* --slide-spacing: 1rem; */
  --slide-size: 78%;

  @media screen and (max-width: 1086px) {
    --slide-size: 100%;
  }
}

.embla__viewport {
  overflow: hidden;
}

.embla__container {
  display: flex;
  touch-action: pan-y pinch-zoom;
  margin-left: calc(var(--slide-spacing) * -1);
}

.embla__slide {
  transform: translate3d(0, 0, 0);
  flex: 0 0 var(--slide-size);
  height: 700px;
  min-width: 0;
  padding-left: var(--slide-spacing);

  @media screen and (max-width: 500px) {
    height: 500px;
  }
}

.embla__controls {
  display: grid;
  grid-template-columns: auto 1fr;
  justify-content: space-between;
  gap: 1.2rem;
  margin-top: 1.8rem;
}

.embla__buttons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  top: 50%;
}

.embla__prev {
  position: absolute;
  left: 50px;
  top: 50%;
  opacity: 0.5;

  @media screen and (max-width: 500px) {
    top: 58%;
  }
}

.embla__prev:hover {
  opacity: 1;
}

.embla__next {
  position: absolute;
  right: 50px;
  top: 50%;
  opacity: 0.5;

  @media screen and (max-width: 500px) {
    top: 58%;
  }
}

.embla__next:hover {
  opacity: 1;
}

.embla__prev:disabled {
  color: var(--detail-high-contrast);
}

.embla__next:disabled {
  color: var(--detail-high-contrast);
}

.embla__button__svg {
  width: 35%;
  height: 35%;
}

.embla__dots {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: center;
  margin-right: calc((2.6rem - 1.4rem) / 2 * -1);
}

.embla__dot {
  -webkit-tap-highlight-color: rgba(var(--text-high-contrast-rgb-value), 0.5);
  -webkit-appearance: none;
  appearance: none;
  background-color: transparent;
  touch-action: manipulation;
  display: inline-flex;
  text-decoration: none;
  cursor: pointer;
  border: 0;
  padding: 0;
  margin: 0;
  width: 2.6rem;
  height: 2.6rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.embla__dot:after {
  box-shadow: inset 0 0 0 0.2rem var(--detail-medium-contrast);
  width: 1.4rem;
  height: 1.4rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  content: '';
}

.embla__dot--selected:after {
  box-shadow: inset 0 0 0 0.2rem var(--text-body);
}

.embla__slide__number {
}