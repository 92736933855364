.sliderText {
  width: 100%;
  text-align: center;
  justify-content: center;
  align-items: center;
  margin-bottom: 50px;
}

.sliderText h2 {
  width: 100%;
  text-align: center;
  justify-content: center;
  align-items: center;
}

.imageWrapper {
  width: 100%;
  height: 700px;
  position: relative;
}

.sliderText p {
  padding-left: 10%;
  padding-right: 10%;
}

.sliderItem {
  width: 100%;
  height: 700px;
  position: relative;
}

.sliderItemOverlay{
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  background: transparent linear-gradient(129deg, #9A9393 0%, #9B9494 0%, #BFBFBF 44%, #FFFFFF 60%, #FFFFFF 100%) 0% 0% no-repeat padding-box;
  mix-blend-mode: multiply;
}

.sliderItemContent {
  width: 100%;
  height: 100%;
  position: absolute;
  padding-top: 50px;
  padding-left: 30px;
  top: 0;
}

.sliderItemContent h2 {
  color: white;
  font-size: 32px;
  font-weight: 400;
}

.sliderItemDescription{
  color: white;
  font-size: 16px;
  font-weight: 400;
  margin-top: 10px;
  text-align: left;
  width: 40%;

  
  @media screen and (max-width: 800px) {
    width: 80%;
  }

  @media screen and (max-width: 605px) {
    font-size: 14px;
    width: 80%;
  }
}

.sliderItemSubImages{
  position: absolute;
  bottom: -50px;
  right: 300px;
  display: flex;
  flex-direction: row;
  transition: all 0.6s ease-out;
  gap: 20px;
  padding: 20px;
  background-color: rgb(140, 184, 40);
}

.sliderItemSubImage1{
  width: 250px;
  height: 150px;
  position: relative;
}

.sliderItemSubImage2{
  width: 250px;
  height: 150px;
  position: relative;
}

.horizontalScroll{
 margin-top: 0; 
}

.title{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}


/* Loading Animation */

.tallShimmer {
  position: relative; /* Add this */
  background-image: linear-gradient(
    to right,
    #f0f0f0 0%,
    #e0e0e0 50%,
    #f0f0f0 100%
  );
  background-size: 460px 700px;
  width: 460px;
  height: 700px;
  animation: shimmer 2s ease infinite;
}

.shortShimmer {
  position: relative; /* Add this */
  background-image: linear-gradient(
    to right,
    #f0f0f0 0%,
    #e0e0e0 50%,
    #f0f0f0 100%
  );
  background-size: 460px 500px;
  width: 460px;
  height: 500px;
  animation: shimmer 2s ease infinite;
}

@keyframes shimmer {
  0% {
    background-position: -460px 0;
  }
  100% {
    background-position: 460px 0;
  }
}




.sliderShimmer {
  position: relative; /* Add this */
  background-image: linear-gradient(
    to right,
    #f0f0f0 0%,
    #e0e0e0 50%,
    #f0f0f0 100%
  );
  background-size: 100% 800px;
  width: 100%;
  height: 800px;
  animation: slider_shimmer 2s ease infinite;
}

@keyframes slider_shimmer {
  0% {
    background-position: -100% 0;
  }
  100% {
    background-position: 100% 0;
  }
}