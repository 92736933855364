.whatsIncluded{
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: start;
  grid-auto-rows: auto;
  gap: 30px 15px;
  justify-content: center;
  grid-auto-flow: row dense;
  

  &::after {
    content: '';
    grid-column: span 2;
  }

  @media screen and (min-width: 992px) {
    grid-template-columns: repeat(4, 1fr);

    &::after {
      content: '';
      grid-column: span 4;
    }
  }

  @media screen and (min-width: 1200px) {
    grid-template-columns: repeat(5, 1fr);

    &::after {
      content: '';
      grid-column: span 5;
    }
  }

  & > * {
    grid-column: span 1;
  }
}

.title{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.description{
  font-weight: 500;
  font-family: var(--body-font);
  text-align: center;
  width: 80%;
  font-size: 15px;
}

.whatsIncludedItem{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.whatsIncludedItem svg{
  width: 40px;
  height: 40px;
  color: var(--color-red);
}

.imageBlock{
  position: relative;
  height: 31px;
  width: 31px;
}

.icon{
  filter: brightness(0) saturate(100%) invert(32%) sepia(95%) saturate(4237%) hue-rotate(348deg) brightness(91%) contrast(107%);
}